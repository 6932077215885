
import { useEffect } from 'react';
import './App.css';
import Approuter from './Router/approuter/approuter';
import { Toaster } from 'react-hot-toast';
function App() {
  useEffect(()=>{

  },[])
  return (
    <div className="App font-manrope">
      <Approuter/>
      <Toaster/>
    </div>
  );
}

export default App;
