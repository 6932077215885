import React from 'react';
import ReactDOM from 'react-dom/client';
import {NextUIProvider} from '@nextui-org/react'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//fontawesome icons
import '@fortawesome/fontawesome-free/css/all.min.css';
//react-toastify
import 'react-toastify/dist/ReactToastify.css';
//prime react
import "primereact/resources/themes/tailwind-light/theme.css";     
import "primereact/resources/primereact.min.css";
//autocomplete
import 'react-bootstrap-typeahead/css/Typeahead.css';
//slick carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import('preline')
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NextUIProvider>
      <App />
    </NextUIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('./service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}
